* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto" !important;
}

:root {
  --primary-color: #284492;
  --primary-light-color: #dae4ff;
  --primary-dark-color: #284492;
  --secondary-color: #f3f3f3;
  --secondary-light-color: #f3f3f3;
  --secondary-dark-color: #c0c0c0;
}

html, body, #app, #app>div {
  height: 100%;
}