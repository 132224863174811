.footer {
  min-height: 130px;
  padding: 16px 0;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1), 0 -2px 2px rgba(0, 0, 0, 0.06),
    0 0 2px rgba(0, 0, 0, 0.07);
  color: var(--primary-color);
  /*position:absolute;
  left:0;
  bottom:0;
  right:0;*/
}
.footer_container {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
}
.footer_container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer_container ul li {
  /*color: rgba(0, 0, 0, 0.56);*/
  /*padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;*/
  transition: all 0.3s ease;
  font-size: 15px;
  font-weight: 400;
}
.footer_container ul li:hover {
  /*cursor: pointer;
  color: rgba(0, 0, 0, 0.87);*/
}
.footer_links_container {
  /*min-width: 200px;*/
}
.footer h3 {
  font-weight: 600;
  margin-bottom: 0px;
}

svg.MuiSvgIcon-root.footer_social_img {
  font-size: 40px;
  margin-left: 10px;
  color: var(--primary-color);
  transition: all 0.3s ease;
}
svg.MuiSvgIcon-root.footer_social_img:hover {
  color: var(--primary-light-color);
 /* color: black;*/
}
