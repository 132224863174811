.address_container {
  width: 100vw;
  /* height: 100vh; */
  background-color: var(--primary-color);
  display: grid;
  height: 100% !important;
}
.address {
  background: #fff;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 5px 12px -6px rgba(0, 0, 0, 0.4);
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.address_in_popup {
  background: #fff;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}
.add_location_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.address_tile {
  margin-bottom: 20px;
  font-size: 20px;
  padding-bottom: 10px;
  text-align: center;
  color: var(--primary-color);

  font-weight: 600;
}
.hidebox {
  display: none;
}
.address_in_popup > form {
  display: flex;
  justify-content: space-evenly;
}
.address > form {
  display: flex;
  justify-content: space-evenly;
}
.lbl_radio {
  display: flex;
  text-align: center;
  box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.35);
  padding: 0px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  color: var(--primary-color);
justify-content: center;
align-items: center;
  border: 2px solid #fff;
}
.lbl_radio_selected {
  display: flex;
  text-align: center;
  box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.35);
  padding: 0px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  width: 100px;
  height: 80px;
  border-radius: 10px;
  color: var(--primary-color);
  justify-content: center;
  align-items: center;
  
  border: 2px solid var(--primary-color);
}

input[type="radio"] :checked + .lbl_radio {
  border-color: var(--primary-color) !important;
}
.address_form_1 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 30px;
  width: 100%;
}
.address_form_2 {
  display: flex;
  flex-direction: column;

  width: 100%;
}
/*
.input_container > input {
  outline-width: 0;
  height: 40px;
  text-transform: none;
  margin-bottom: 10px;
  border-radius: 10px;
  border: none;
  width: 100%;

  padding: 10px;
  background-color: var(--primary-light-color);
}

.label_container > label {
  color: var(--primary-color);
}
*/
.add_address {
  padding: 10px;
  font-size: 15px;
  color: var(--primary-color);
  border-radius: 10px;
  background-color: var(--primary-light-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  width: 70%;
}
