.loginScreen_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100% !important;
  align-items: center;
  color: var(--primary-color);
}
img.logo {
  width: 200px !important;
}
.signup_input_container > input {
  outline-width: 0;
  height: 40px;
  max-width: 200px !important;
  text-transform: none;
  background-color: var(--primary-light-color);
  border-radius: 10px;
  border: none;
  padding: 10px;
}
.loginScreen_container {
  display: flex;
  flex-direction: column;
  background: white;
  max-width: 500px;
  width: 40%;
  min-width: 200px;
  box-shadow: 0px 5px 12px -6px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  padding: 14px;
}

.loginScreen_logo {
  display: flex;
  justify-content: center;
}
label {
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
}
input {
  font-size: 15px;
}
.loginScreen_container > button {
  padding: 10px;
  color: white;
  border-radius: 10px;
  background-color: var(--primary-color);
  font-size: 15px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.loginScreen_signUp {
  color: var(--primary-color);
  font-weight: 200;
  font-size: 14px;
  padding-top: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}
.loginScreen_signUp:hover {
  text-decoration: underline;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.signup_input_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.signup_input_container > label {
font-size: 20px;
}